import React from 'react';
import { Provider } from 'react-redux';

import Payments from '../../payments/payments/components/Payments';
import store from './store';

const Root = () => (
  <Provider store={store}>
    <Payments />
  </Provider>
);

export default Root;
